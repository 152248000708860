// import "./ResumeProductInventoryDetail.scss";
import React, { useCallback, useEffect } from "react";
import { styled, Box } from "@mui/system";
import "./WalletDetails.scss";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  getWalletsNameContains,
  rechargeWallet,
} from "../../../services/utilsWallet";
import DefaultImg from "../../../assets/market-icons/avatar-supervisor.jpg";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Modal from "@mui/material/Modal";
import { debounce } from "lodash";
import { LinearProgress } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { WalletSharp } from "@mui/icons-material";



const WalletDetails = (props) => {
  const {
    setSelectedWallet,
    selectedWallet,
    walletInfo,
    setWalletInfo,
    loading,
    setLoading,
    recharge,
    setRechage,
  } = props;
  const [wallets, setWallets] = useState([]);
  const [inputText, setInputText] = useState("");
  const [selectedOption, setSelectedOption] = useState("50000");
  const [selectedType, setSelectedType] = useState("cashier");
  const [quantity, setQuantity] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [supervisorProfile, setSupervisorProfile] = useState(
    () => JSON.parse(window.localStorage.getItem("profile"))
  )

  const [loadingCustomer, setLoadingCustomers] = useState(false);

  // Modal states
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);

  const [amount, setAmount] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(0);


  const formatMoney = (number) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };
  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };
  const getNewQuantity = (walletQuantity) => {
    if (selectedOption == "otro") {
      if (quantity === "") {
        return parseInt(walletQuantity);
      } else {
        return parseInt(quantity) + parseInt(walletQuantity);
      }
    } else {
      return parseInt(walletQuantity) + parseInt(selectedOption);
    }
  };
  const getAmount = () => {
    if (selectedOption == "otro") {
      return parseInt(quantity);
    } else {
      return parseInt(selectedOption);
    }
  };

  // Open confirmation modal
  const handleOpenConfirmModal = () => {
    const calculatedAmount = getAmount() * 100;
    const calculatedCurrentAmount = getNewQuantity(selectedWallet.wallet_type === 1 ? selectedWallet.wallet_balance / 100 : selectedWallet.current_balance / 100) * 100;

    setAmount(calculatedAmount);
    setCurrentAmount(calculatedCurrentAmount);
    setOpenConfirmModal(true);
  };

  // Close confirmation modal
  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const BlueButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#0070A1',
    '&:hover': {
      backgroundColor: '#005d84',
    },
  }));




  const handleRecharge = (event) => {
    let fkOrganization = localStorage.getItem("fk_organization");
    //TODO 
    let jsonBody = {
      wallet_type: selectedWallet.wallet_type,
      wallet_id: selectedWallet.id,
      email: selectedWallet.email,
      type: selectedType,
      fk_ontrack_organization: fkOrganization,
      amount: getAmount() * 100,
      description: `Top up amount: ${getAmount() * 100} method: ${selectedType.toUpperCase()}`,
      payin_info: {
        cashier: {
          fk_worker: supervisorProfile.profile.user.supervisor.id,
        },
      },
      payer: {
        name: selectedWallet.name,
        email: selectedWallet.email,
        phone: selectedWallet.mobile_phone,
        document: {
          documentId: selectedWallet.id_number,
          type: "CC",
        },
        billingAddress: {
          zipCode: "111111",
          state: "Cundinamarca",
          city: "Bogotá",
        },
      },
    };
    rechargeWallet(JSON.stringify(jsonBody)).then((data) => {

      handleCloseConfirmModal();
      setOpenModal(true);
      // window.location.reload();
    });
    // window.location.reload();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };


  const [currentPage, setCurrentPage] = useState(1);
   
  const [numPages, setNumPages] = useState(1); 
  const [searchText, setSearchText] = useState('');


  const debouncedSearch = useCallback(
    debounce((text) => {
      setLoadingCustomers(true);
      let fkOrganization = localStorage.getItem("fk_organization");
      getWalletsNameContains(fkOrganization, text, 1, 6).then((data) => {
        setWallets(data.wallets);
        setNumPages(data.pagination.num_pages);
        setLoadingCustomers(false);
      });
    }, 500),
    []
  );
  
  const handleTextChange = (event) => {
    setSearchText(event.target.value);
    debouncedSearch(event.target.value);
  };
  
  useEffect(() => {
    debouncedSearch(searchText);
  }, [searchText, debouncedSearch]);
  
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setLoadingCustomers(true);
    let fkOrganization = localStorage.getItem("fk_organization");
    getWalletsNameContains(fkOrganization, searchText, value, 10).then((data) => {
      setWallets(data.wallets);
      setNumPages(data.pagination.num_pages);
      setLoadingCustomers(false);
    });
  };


  const style = {
    position: "absolute",
    width: "100%",
    height: "100%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    display: "grid",
    placeItems: "center",
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-box">
            <h2 className="modal-title">
              Se realizó la recarga exitosamente
            </h2>
            <div className="text-center modal-options">
              <Button >
                <a href="/billeteras">Continuar</a>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {selectedWallet ? (
        <div className="w-d-row-1">
          <div className="w-rowback">
            <ArrowBackIcon className="cursor-pointer"
              onClick={(e) => {
                setSelectedWallet(null);
                setInputText("");
                setRechage(false);
                // setWalletInfo(null);
                // setLoading(true);
              }}
            ></ArrowBackIcon>
            <li>Detalle de Billetera</li>
          </div>
          <div className="detail-wallet-info">
            <div className="detail-image-wallet">
              <img
                src={selectedWallet.image || DefaultImg}
                alt=""
                className="image-wallet-balance"
                onError={(e) => {
                  e.target.src = DefaultImg;
                }}
              />
            </div>
            <div>
              <li className="wallet-user-name">{selectedWallet.name}</li>
              <li className="wallet-user-sub-title">{selectedWallet?.id_number ? `ID: ${selectedWallet.id_number}` : `ID: ${selectedWallet.code}`}</li>
            </div>


          </div>
          <div>
            {recharge ? (
              <>
                <div className="amount-recharge">
                  <li className="currect-amout-text">Saldo Actual:</li>
                  <li className="actual-wallet-quantity">
                    {selectedWallet.wallet_type === 1 ? (
                      <>{formatMoney(selectedWallet.wallet_balance / 100)}</>
                    ) : (
                      <>{formatMoney(selectedWallet.current_balance / 100)}</>
                    )}
                  </li>
                </div>
                <div className="pay-in-type-sect">
                  <li>Medio de pago</li>
                  <select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className="type-topup-form-select"
                  >
                    {/* {[1, 2].includes(selectedWallet.wallet_type) && <option value="payroll_deduction">Descuento de nómina</option>} */}
                    <option value="cashier">Efectivo</option>
                    <option value="cashier_card">Tarjeta de credito</option>
                  </select>
                </div>
                <div className="pay-in-wallet-sect">
                  <li> Valor a Recargar</li>
                  <div className="row-wallet-recharge-options">
                    <label className="input-label-wallet-amount">
                      <input
                        type="radio"
                        value="50000"
                        checked={selectedOption === "50000"}
                        onChange={() => setSelectedOption("50000")}
                        className="input-radio-pi"
                      />
                      $50.000
                    </label>
                    <label className="input-label-wallet-amount">
                      <input
                        type="radio"
                        value="100000"
                        checked={selectedOption === "100000"}
                        onChange={() => setSelectedOption("100000")}
                        className="input-radio-pi"
                      />
                      $100.000
                    </label>
                    <label className="input-label-wallet-amount">
                      <input
                        type="radio"
                        value="200000"
                        checked={selectedOption === "200000"}
                        onChange={() => setSelectedOption("200000")}
                        className="input-radio-pi"
                      />
                      $200.000
                    </label>
                    <label className="input-label-wallet-amount">
                      <input
                        type="radio"
                        value="400000"
                        checked={selectedOption === "400000"}
                        onChange={() => setSelectedOption("400000")}
                        className="input-radio-pi"
                      />
                      $400.000
                    </label>
                    <div className="other-input-wallet">
                      <label className="input-label-wallet-amount">
                        <input
                          type="radio"
                          value="otro"
                          checked={selectedOption === "otro"}
                          onChange={() => setSelectedOption("otro")}
                          className="input-radio-pi"
                        />
                        Otro
                      </label>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        disabled={selectedOption !== "otro"}
                        onChange={handleQuantityChange}
                        style={{
                          fontFamily: "Catamaran-Light",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="amount-recharge">
                  <li className="new-quatity-wallet">Nuevo Saldo:</li>
                  <li className="actual-wallet-quantity">
                    {selectedWallet.wallet_type === 1 ? (
                      // <>{selectedWallet.wallet_balance / 100}</>
                      <>
                        {formatMoney(
                          getNewQuantity(selectedWallet.wallet_balance / 100)
                        )}
                      </>
                    ) : (
                      <>
                        {formatMoney(
                          getNewQuantity(selectedWallet.current_balance / 100)
                        )}
                      </>
                    )}
                  </li>
                </div>
                <div className="button-recharge-cancel">
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      fontSize: "12px",
                      backgroundColor: "#D24300",
                      fontFamily: "Catamaran-Light",
                    }}
                    className="cancel-button"
                    onClick={(e) => {
                      setRechage(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      fontSize: "12px",
                      backgroundcolor: "#0070A1",
                      fontFamily: "Catamaran-Light",
                    }}
                    className="recharge-button"
                    onClick={handleOpenConfirmModal}
                  >
                    Confirmar Recarga
                  </Button>

                  {/* Confirmation modal */}
                  <Modal
                    open={openConfirmModal}
                    onClose={handleCloseConfirmModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div className="modal-box-confirm">
                        <h2 className="modal-title-confirm">
                          Confirmar recarga
                        </h2>
                        <p className="modal-text-confirm">
                          ¿Confirma la recarga por {formatMoney(amount / 100)} para {selectedWallet.name}? El nuevo saldo será de {formatMoney(currentAmount / 100)}
                        </p>
                        <div className="modal-options-confirm">
                          <Button variant="text" color="primary" onClick={handleCloseConfirmModal}>
                            No
                          </Button>
                          {/* <BlueButton variant="contained" color="primary" onClick={handleRecharge}> */}
                          <BlueButton variant="contained" color="primary" onClick={(event) => handleRecharge(event)}>
                            Sí
                          </BlueButton>
                        </div>
                      </div>
                    </Box>
                  </Modal>

                </div>
              </>
            ) : (
              <>
                {!loading && (
                  <>
                    {selectedWallet.wallet_type === 1 ? (
                      <div>
                        <div className="detail-amount-info-wallet">
                          Saldo Disponible
                          <div className="amount-recharge">
                            <span className="amount-text">
                              {formatMoney(
                                walletInfo.customer_wallet_info.wallet_balance /
                                100
                              )}
                            </span>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                fontSize: "10px",
                                backgroundcolor: "#0070A1",
                                fontFamily: "Muli-Regular",
                              }}
                              className="recharge-button"
                              onClick={(e) => {
                                setRechage(true);
                              }}
                            >
                              Recargar
                            </Button>
                          </div>
                        </div>
                        <div>
                          Estudiantes a cargo
                          {walletInfo.trackables_wallet_info.map(
                            (trackable, index) => (
                              <div className="row-wallet-information">
                                <div className="row-wallet-information-image">
                                  <img
                                    src={trackable.image || DefaultImg}
                                    alt=""
                                    className="image-wallet-balance"
                                    onError={(e) => {
                                      e.target.src = DefaultImg;
                                    }}
                                  />
                                </div>
                                <div className="row-wallet-information-text">
                                  <li className="li-name">{trackable.name}</li>
                                  {/* <li className="li-rol">El rol</li> */}
                                  <div>
                                    <li className="li-balance">
                                      Disponible:
                                      {formatMoney(
                                        trackable.current_balance / 100
                                      )}
                                    </li>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="detail-amount-info-wallet">
                          Saldo Disponible
                          <div className="amount-recharge">
                            <span className="amount-text">
                              {formatMoney(
                                selectedWallet.current_balance / 100
                              )}
                            </span>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                fontSize: "10px",
                                backgroundcolor: "#0070A1",
                                fontFamily: "Muli-Regular",
                              }}
                              className="recharge-button"
                              onClick={() => {
                                setRechage(true);
                              }}
                            >
                              Recargar
                            </Button>
                          </div>
                        </div>
                        <div>
                          Acudientes asociados
                          {walletInfo.customer_wallets_info.map(
                            (customer, index) => (
                              <div className="row-wallet-information">
                                <div className="row-wallet-information-image">
                                  <img
                                    src={customer.first_name || DefaultImg}
                                    alt=""
                                    className="image-wallet-balance"
                                    onError={(e) => {
                                      e.target.src = DefaultImg;
                                    }}
                                  />
                                </div>
                                <div className="row-wallet-information-text">
                                  <li className="li-name">
                                    {customer.first_name}
                                  </li>
                                  {/* <li className="li-rol">El rol</li> */}
                                  <div>
                                    <li className="li-balance">
                                      Disponible:
                                      {formatMoney(
                                        customer.wallet_balance / 100
                                      )}
                                    </li>
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {/* <div>
                          <li className="person-in-charge">Persona a cargo</li>
                          <div className="row-wallet-information">
                            <div className="row-wallet-information-image">
                              <img
                                src={
                                  walletInfo.customer_wallet_info.image ||
                                  DefaultImg
                                }
                                alt=""
                                className="image-wallet-balance"
                                onError={(e) => {
                                  e.target.src = DefaultImg;
                                }}
                              />
                            </div>
                            <div className="row-wallet-information-text">
                              <li className="li-name">
                                {walletInfo.customer_wallet_info.first_name +
                                  " " +
                                  walletInfo.customer_wallet_info.last_name}
                              </li>
                              <div>
                                <li className="li-balance">
                                  Disponible:
                                  {formatMoney(
                                    walletInfo.customer_wallet_info
                                      .wallet_balance / 100
                                  )}{" "}
                                </li>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="w-d-row">
          Billeteras
          <div>

            <SearchIcon></SearchIcon>
            <TextField
              id="standard-basic"
              variant="standard"
              onChange={handleTextChange}
            />
          </div>
          <div className="wallet-list-detail">
            {loadingCustomer && <LinearProgress />}
            {wallets.map((wallet, index) => (
              <div className="row-wallet-information cursor-pointer" onClick={() => {
                setSelectedWallet(wallet);
              }}>
                <div className="row-wallet-information-image">
                  <img
                    src={wallet.image || DefaultImg}
                    alt=""
                    className="image-wallet-balance"
                    onError={(e) => {
                      e.target.src = DefaultImg;
                    }}
                  />
                </div>
                <div className="row-wallet-information-text">
                  <li className="li-name">{wallet.name}</li>
                  {/* <li className="li-rol">El rol</li> */}
                  <div>
                    {wallet.wallet_type === 1 ? (
                      <li className="li-balance">
                        Disponible: {formatMoney(wallet.wallet_balance / 100)}{" "}
                        <InfoIcon></InfoIcon>
                      </li>
                    ) : (
                      <li className="li-balance">
                        Disponible: {formatMoney(wallet.current_balance / 100)}{" "}
                        <InfoIcon
                          onClick={(e) => {
                            setSelectedWallet(wallet);
                          }}
                        ></InfoIcon>
                      </li>
                    )}
                  </div>
                </div>
                <div className="row-wallet-information-refund">
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      fontSize: "10px",
                      backgroundColor: "#0070A1",
                      fontFamily: "Catamaran-Light",
                      cursor: 'pointer',
                      userSelect: 'none'
                    }}
                    onClick={(e) => {
                      setRechage(true);
                      setSelectedWallet(wallet);
                    }}
                  >
                    Recargar
                  </Button>
                </div>
              </div>
            ))}
            {/* pagination for wallets */}
            <div>
              <Pagination
                count={numPages} 
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
                className="pagination"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WalletDetails;
